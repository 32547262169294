import type {GetServerSideProps, GetServerSidePropsResult} from 'next';
import {parseCookies} from 'nookies';
import {
  type GetUserDataForAnalyticsResponse,
  getUserDataForAnalytics,
} from '../core/repositories/usersMeRepository';
import {withHandler} from '../server/token/withHandler';
import {ScriptForGA} from '../shared/components/ScriptForGA';
import {LoggedInTop} from '../features/top/components/Container/LoggedInTop';
import {NoLeftSidebarLayout} from '../shared/components/layouts/NoLeftSidebarLayout';
import {LoggedInTopLayout} from '../features/top/components/Container/LoggedInTopLayout';
import {NoLoggedInTop} from '../features/top/components/Container/NoLoggedInTop';
import {validateCookie} from '../core/usecases/auth/checkOauthTokenExistence';
import {type NextPageWithLayout} from './_app';

type Props = {
  userDataForAnalytics?: GetUserDataForAnalyticsResponse;
};

export const getServerSideProps: GetServerSideProps = withHandler<
  Props,
  'withOptionalSession'
>(
  {auth: 'optional'},
  async (context): Promise<GetServerSidePropsResult<Props>> => {
    const props: Props = {};
    const {cookies} = context.req;
    const {isValid} = validateCookie(cookies);

    if (!isValid) return {props};

    const userDataResponse = await getUserDataForAnalytics({cookies});
    if (!userDataResponse.ok) {
      return {
        props,
      };
    }

    return {
      props: {
        ...props,
        userDataForAnalytics: userDataResponse.value,
      },
    };
  },
);

// トップ画面
const Page: NextPageWithLayout<Props> = ({userDataForAnalytics}: Props) => {
  const cookies = parseCookies();
  const {isValid} = validateCookie(cookies);

  return (
    <>
      {isValid ? (
        <LoggedInTopLayout>
          <LoggedInTop />
        </LoggedInTopLayout>
      ) : (
        <NoLeftSidebarLayout>
          <NoLoggedInTop />
        </NoLeftSidebarLayout>
      )}
      <ScriptForGA userDataForAnalytics={userDataForAnalytics} />
    </>
  );
};

Page.getLayout = (page) => <>{page}</>;

export default Page;
