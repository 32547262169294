import {Box} from '@mantine/core';
import React, {useEffect, useState} from 'react';
import {type RecruitmentSchedulesScheme} from '../../../../../core/entities/recruitment/schema';
import {ScheduleButton} from '../../../../recruitment/components/ScheduleButton';

type Props = {
  schedules: RecruitmentSchedulesScheme[];
  recruitmentId: number;
  isSp: boolean;
};

type FormatDate = {
  dayOfWeek: string;
  month: number;
  day: number;
  time: string;
};

export const Schedules: React.FC<Props> = ({
  schedules,
  recruitmentId,
  isSp,
}) => {
  // 他の日程表示の制御
  const [shouldContractSchedules, setShouldContractSchedules] = useState(false);
  const DEFAULT_SHOWING_SCHEDULES_NUMBER = 2;
  useEffect(() => {
    if (schedules.length > DEFAULT_SHOWING_SCHEDULES_NUMBER) {
      setShouldContractSchedules(true);
    }
  }, [schedules]);

  const parseDateFormat = (dateStr: string, isAllDay: boolean) => {
    if (dateStr === '0001-01-01T00:00:00Z') {
      return null;
    }

    const formatDate: FormatDate = {
      dayOfWeek: '',
      month: 0,
      day: 0,
      time: '',
    };
    const dateObj = new Date(dateStr);
    formatDate.dayOfWeek = '日月火水木金土'.charAt(dateObj.getDay());
    formatDate.month = dateObj.getMonth() + 1;
    formatDate.day = dateObj.getDate();
    formatDate.time = isAllDay
      ? ''
      : `${dateObj.getHours()}:${`0${dateObj.getMinutes()}`.slice(-2)}`;
    return formatDate;
  };

  const getDeadLine = (schedule: RecruitmentSchedulesScheme, isSp: boolean) => {
    const deadline = parseDateFormat(
      schedule.entryEnd,
      schedule.isEntryEndAllDay,
    );
    if (!deadline) {
      return null;
    }

    return (
      <dd className="clear-right float-right ml-4 mt-2  text-notification-1 text-lg">
        <span className="pr-[3px] leading-6 text-[22px]">
          {deadline.month}/{deadline.day}
        </span>
        <span className="text-base align-baseline">
          <span className="inline-block mx-[2px] text-[11px]">{`(${deadline.dayOfWeek})`}</span>
          <span className="inline-block mx-[2px] text-[11px]">
            {deadline.time}
          </span>
          {isSp && <br />}
          <span className="inline-block mx-[2px] text-[11px]">
            {schedule.applyMethod.label}
          </span>
        </span>
      </dd>
    );
  };

  const getPlace = (schedule: RecruitmentSchedulesScheme) => {
    if (!schedule.place) {
      return null;
    }

    const MAX_PLACE_LENGTH = 25;

    return (
      <p className="inline text-sm">
        場所:{' '}
        {schedule.place.length > MAX_PLACE_LENGTH
          ? `${schedule.place.slice(0, MAX_PLACE_LENGTH)}...`
          : schedule.place}
      </p>
    );
  };

  const getEventDate = (schedule: RecruitmentSchedulesScheme) => {
    const eventDate = [];
    const eventStart = parseDateFormat(
      schedule.eventStart,
      schedule.isEventStartAllDay,
    );
    const eventEnd = parseDateFormat(
      schedule.eventEnd,
      schedule.isEventEndAllDay,
    );
    if (eventStart) {
      const formatStartStr = `${eventStart.month}/${eventStart.day}(${eventStart.dayOfWeek}) ${eventStart.time}`;
      eventDate.push(formatStartStr);
    }

    if (eventEnd) {
      const formatEndStr = `${eventEnd.month}/${eventEnd.day}(${eventEnd.dayOfWeek}) ${eventEnd.time}`;
      eventDate.push(formatEndStr);
    }

    if (eventDate.length === 2) {
      const eventDateStr = `日程: ${eventDate.join('〜')}`;
      return <p className="inline text-sm">{eventDateStr}</p>;
    }

    if (eventDate.length === 1) {
      const eventDateStr = `日程: ${eventDate[0]}`;
      return <p className="inline text-sm">{eventDateStr}</p>;
    }
  };

  const getEventDateAndPlace = (
    schedule: RecruitmentSchedulesScheme,
    isSp: boolean,
  ) => {
    return (
      <dd
        className={`pt-1.5 leading-7 ${isSp ? 'text-black-2' : 'text-black-1'}`}
      >
        <div className="inline-block w-96 overflow-hidden whitespace-nowrap overflow-ellipsis">
          {getEventDate(schedule)}
          {isSp && <br />}
          {getPlace(schedule)}
        </div>
      </dd>
    );
  };

  const showAllSchedules = () => {
    setShouldContractSchedules(false);
  };

  return (
    <Box className="bg-white">
      <div className={schedules.length > 0 ? `bg-background-1 p-[5px]` : ''}>
        {schedules.map((schedule, index) => {
          return (
            <dl
              className={`p-[10px] flow-root ${index + 1 <= DEFAULT_SHOWING_SCHEDULES_NUMBER || !shouldContractSchedules ? '' : 'hidden'}`}
              key={schedule.id}
            >
              <dd className="float-right rounded px-[5px] text-sm">
                <ScheduleButton
                  scheduleId={schedule.id}
                  isRegisteredInital={schedule.isCalendarRegistered}
                  registeredCountInitial={schedule.calendarRegisteredCount}
                />
              </dd>
              {getDeadLine(schedule, isSp)}
              <dt className="font-bold leading-sm text-base text-black-1">
                {schedule.name}
              </dt>
              {getEventDateAndPlace(schedule, isSp)}
            </dl>
          );
        })}
      </div>
      {shouldContractSchedules && (
        <div
          onClick={showAllSchedules}
          className="text-center p-[5px] text-[11px] cursor-pointer hover:opacity-80 text-black-1"
        >
          ...ほか
          {schedules.length - DEFAULT_SHOWING_SCHEDULES_NUMBER}日程
        </div>
      )}
    </Box>
  );
};
