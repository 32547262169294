import {z} from 'zod';
import {COLUMN_LIMITED_CONDITIONS} from './constants';

export const ColumnListItemSchema = z.object({
  id: z.string(),
  carouselThumbnailURL: z.string().optional(),
  thumbnailURL: z.string().optional(),
  category: z.string(),
  subCategory: z.string(),
  tags: z.array(z.string()),
  publishDateTime: z.string(), // ISO 8601形式の文字列
  updateDateTime: z.string(), // ISO 8601形式の文字列
  title: z.string(),
  contentSummary: z.string(),
  favorited: z.boolean(),
  favoriteCount: z.number(),
  special: z
    .object({
      slug: z.string(),
    })
    .optional(),
  companyPages: z
    .array(
      z.object({
        companyID: z.number(),
        slug: z.string(),
      }),
    )
    .optional(),
  sponsorCompanies: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    )
    .optional(),
});
export type ColumnListItemSchema = z.infer<typeof ColumnListItemSchema>;

export const ColumnPostListSchema = z.object({
  posts: z.array(ColumnListItemSchema),
});

const ColumnLimitedCondition = z.enum([COLUMN_LIMITED_CONDITIONS.LOGIN]);

// GET /3.0/column/posts/{id}
export const PostSchema = z.object({
  id: z.string(),
  thumbnailURL: z.string().optional(),
  ogpImageURL: z.string().optional(),
  headerURL: z.string().optional(),
  category: z.string(),
  categoryJP: z.string().optional(),
  subCategory: z.string(),
  subCategoryJP: z.string().optional(),
  tags: z.array(z.string()),
  publishDateTime: z.string(), // ISO 8601形式の文字列
  updateDateTime: z.string(), // ISO 8601形式の文字列
  title: z.string(),
  description: z.string().optional(),
  content: z.string(),
  contentHTML: z.string().optional(),
  favorited: z.boolean(),
  favoriteCount: z.number(),
  companyPages: z
    .array(
      z.object({
        companyID: z.number(),
        slug: z.string(),
      }),
    )
    .optional(),
  sponsorCompanies: z
    .array(
      z.object({
        id: z.number(),
        name: z.string(),
      }),
    )
    .optional(),
  limitedConditions: z.array(ColumnLimitedCondition).nullable(),
  redirectTo: z.string(),
  special: z
    .object({
      Slug: z.string(),
    })
    .nullable(),
});
export const PostListSchema = z.object({
  id: z.string(),
  thumbnailURL: z.string().optional(),
  carouselThumbnailURL: z.string().optional(),
  category: z.string(),
  subCategory: z.string(),
  tags: z.array(z.string()),
  publishDateTime: z.string(),
  updateDateTime: z.string(),
  title: z.string(),
  contentSummary: z.string(),
  favorited: z.boolean(),
  favoriteCount: z.number(),
});

export type PostSchema = z.infer<typeof PostSchema>;
export type PostListSchema = z.infer<typeof PostListSchema>;

export const GetPostResponse = z.object({
  post: PostSchema,
});
export type GetPostResponse = z.infer<typeof GetPostResponse>;
export const GetPostListResponse = z.object({
  posts: z.array(PostListSchema),
});
export type GetPostListResponse = z.infer<typeof GetPostListResponse>;

export const SpecialPostSchema = z.object({
  id: z.string(),
  title: z.string(),
});

export const SpecialPostListSchema = z.object({
  posts: z.array(SpecialPostSchema),
});

export const ColumnCategorySchema = z.object({
  slug: z.string(),
  name: z.string(),
  description: z.string(),
  count: z.number(),
});

export const ColumnCategoriesSchema = z.object({
  categories: z.array(
    z.object({
      category: ColumnCategorySchema,
      children: z.array(
        z.object({
          category: ColumnCategorySchema,
        }),
      ),
    }),
  ),
});

export const RankingColumnListSchema = z.object({
  posts: z.array(ColumnListItemSchema),
});

export const ColumnCarouselSchema = z.object({
  id: z.string(),
  imageURL: z.string(),
  title: z.string(),
  category: z.string(),
  subCategory: z.string(),
});

export const GetColumnCarouselList = z.object({
  carousel: z.array(ColumnCarouselSchema),
});

export type ColumnCarousel = z.infer<typeof ColumnCarouselSchema>;

export const GetPreviewCodeResponseSchema = z.object({
  previewCode: z.string(),
});
export type GetPreviewCodeResponse = z.infer<
  typeof GetPreviewCodeResponseSchema
>;
