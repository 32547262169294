import {Loader} from '@mantine/core';
import {BasicButton} from '@packages/ui';
import {MdInsertDriveFile} from 'react-icons/md';
import {ReportType} from '../../../../core/entities/reports/constants';
import {useReports} from '../../../../core/repositories/reportsRepository';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {COLOR_PRIMARY} from '../../../../config/constants/colorCodes';
import {getMailAuthLinkWithParamsHooks} from '../../../../shared/services/getMailAuthLinkWithParamsHooks';

export const NoLoggedInReportList: React.FC = () => {
  const {data, isLoading} = useReports({
    queryParams: {
      limit: 8,
      types: [ReportType.SELECTION],
      departmentMasterIDs: [],
      page: 0,
    },
  });

  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="w-full bg-white py-16">
      <div className="h-[52px] px-16 flex justify-start items-center">
        <div className="w-24 h-24">
          <MdInsertDriveFile
            size={24}
            className="mx-auto"
            fill={COLOR_PRIMARY}
          />
        </div>
        <h2 className="ml-12 text-lg font-bold text-black-1">ES・体験記</h2>
      </div>
      <div>
        {data?.ok && !isLoading ? (
          data.value.reports.map((report, index) => (
            <div
              key={`report-${index}`}
              className="w-full py-8 px-16 [&:nth-child(1)]:mt-0 border-b-[1px] border-line border-collapse [&:nth-child(1)]:border-t-[1px]"
            >
              <CommonLink
                href={`/selection_reports/${report.id}`}
                className="w-full "
              >
                <div className="flex justify-start items-center">
                  {report.isNew && (
                    <TagComponent
                      isNew={report.isNew}
                      reporterGetOffer={false}
                    />
                  )}
                  <TagComponent
                    isNew={false}
                    reporterGetOffer={report.reporterGetOffer}
                  />
                </div>
                <p className="w-full mt-8 font-bold text-black-1 text-base">
                  {report.companyName}【
                  {`${report.reporterGraduationYear}`.slice(2)}卒】
                  {report.departmentCategory.name} の本選考体験記
                </p>
              </CommonLink>
            </div>
          ))
        ) : (
          <div className="h-[200px] flex justify-center items-center">
            <Loader color="gray" size="lg" />
          </div>
        )}
      </div>
      <div className="w-full mt-16 px-16">
        <BasicButton
          isPrimary={true}
          isLarge={true}
          label="無料会員登録してもっと見る"
          onClick={() => {
            location.href = mailAuthLink;
          }}
          className="w-full"
        />
      </div>
    </div>
  );
};

const TagComponent: React.FC<{isNew: boolean; reporterGetOffer: boolean}> = ({
  isNew,
  reporterGetOffer,
}) => {
  const LABEL = isNew ? 'NEW' : reporterGetOffer ? '内定' : '最終落ち';
  const BG_CLASS = isNew
    ? 'bg-category-1'
    : reporterGetOffer
      ? 'bg-category-3'
      : 'bg-category-5';

  return (
    <div
      className={`w-[70px] text-white text-sm px-4 py-[2px] mr-8 ${BG_CLASS}`}
    >
      <p className="text-white text-xs text-center">{LABEL}</p>
    </div>
  );
};
