import {
  useGraduationYearsHot,
  useInfiniteRecruitmentList,
  useInfiniteRecruitmentListForTop,
} from '../../../core/repositories/recruitingInfoRepository';

export const useRecruitments = () => {
  const {data: graduationYearsHots, isLoading: isLoadingGraduationYearsHot} =
    useGraduationYearsHot({});

  // GraduationYearsHots を使って二つの年度対象の募集情報を取得
  const {data, isLoading: isLoadingRecruitments} =
    useInfiniteRecruitmentListForTop({
      queryParams: {
        limit: 100,
        industryCategoryIDs: [],
        order: 'recent',
        tagIDs: [],
        typeIDs: [],
        groupedJobTypeIDs: [],
        page: 1,
        gy:
          graduationYearsHots?.ok && graduationYearsHots.value.years[0]
            ? graduationYearsHots.value.years[0]
            : 0,
        allgy: 'false',
      },
    });

  const {
    data: nextGraduationYearRecruitments,
    isLoading: isLoadingNextGraduationYearRecruitments,
  } = useInfiniteRecruitmentList({
    queryParams: {
      limit: 10,
      industryCategoryIDs: [],
      order: 'recent',
      tagIDs: [],
      typeIDs: [],
      groupedJobTypeIDs: [],
      page: 1,
      gy:
        graduationYearsHots?.ok && graduationYearsHots.value.years[1]
          ? graduationYearsHots.value.years[1]
          : 0,
      allgy: 'false',
    },
  });

  // 学年不問の募集情報取得
  const {
    data: allGraduationYearRecruitments,
    isLoading: isLoadingAllGraduationYearRecruitments,
  } = useInfiniteRecruitmentList({
    queryParams: {
      limit: 100,
      industryCategoryIDs: [],
      order: 'recent',
      tagIDs: [],
      typeIDs: [],
      groupedJobTypeIDs: [],
      page: 1,
      gy: 0,
      allgy: 'true',
    },
  });

  const allYearRecruitments =
    allGraduationYearRecruitments?.pages[0]?.recruitments.filter(
      (recruitment) => {
        return recruitment.targetYear === 0;
      },
    );

  const nextGraduationYearRecruitmentsFiltered = graduationYearsHots?.ok
    ? nextGraduationYearRecruitments?.pages[0]?.recruitments.filter(
        (recruitment) => {
          return recruitment.targetYear === graduationYearsHots.value.years[1];
        },
      )
    : [];

  return {
    graduationYearsHots,
    isLoadingGraduationYearsHot,
    recruitments: data,
    nextGraduationYearRecruitments,
    nextGraduationYearRecruitmentsFiltered,
    allYearRecruitments,
    isLoadingAllGraduationYearRecruitments,
    isLoadingRecruitments,
    isLoadingNextGraduationYearRecruitments,
  };
};
