import {Box} from '@mantine/core';
import {HiOfficeBuilding} from 'react-icons/hi';
import {IoIosPlayCircle, IoMdPeople} from 'react-icons/io';
import {MdExplore, MdForum, MdOutlineCardMembership} from 'react-icons/md';
import Link from 'next/link';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';
import {formatDateFromIsoDate} from '../../../../shared/services/formatDate';
import {usePutReadNotification} from '../../../../core/repositories/notificationsRepository';

export type NotificationItem = {
  id: number;
  title: string;
  body: string;
  detailURL: string;
  isUnread: boolean;
  eventType: string;
  createdAt: string;
};

type Props = {
  notification: NotificationItem;
};

export const getIcon = (eventType: string) => {
  switch (eventType) {
    case 'GsCompany': {
      return <HiOfficeBuilding size="30" className="mx-auto" />;
    }

    case 'GsRecruitingItem':
    case 'RemindEntryBefore3Days':
    case 'RemindEventYesterday': {
      return <IoMdPeople size="30" className="mx-auto" />;
    }

    case 'Question':
    case 'QuestionAnswerLike':
    case 'QuestionAnswerReply':
    case 'QuestionAnswerSummary': {
      return <MdForum size="30" className="mx-auto" />;
    }

    case 'WpPost': {
      return <MdExplore size="30" className="mx-auto" />;
    }

    case 'WebSeminar': {
      return <IoIosPlayCircle size="30" className="mx-auto" />;
    }

    case 'LongInternRecruitment': {
      return <MdOutlineCardMembership size="30" className="mx-auto" />;
    }

    default: {
      return <IoMdPeople size="30" className="mx-auto" />;
    }
  }
};

export const getTypeName = (eventType: string) => {
  // EventTypeによって表示する文字列を返す
  switch (eventType) {
    case 'GsCompany': {
      return '企業情報';
    }

    case 'GsRecruitingItem': {
      return '募集情報';
    }

    case 'RemindEntryBefore3Days': {
      return '募集情報';
    }

    case 'RemindEventYesterday': {
      return '募集情報';
    }

    case 'Question':
    case 'QuestionAnswerLike':
    case 'QuestionAnswerReply':
    case 'QuestionAnswerSummary': {
      return 'コミュニティ';
    }

    case 'WpPost': {
      return '就活コラム';
    }

    case 'WebSeminar': {
      return 'Web合説';
    }

    case 'LongInternRecruitment': {
      return '長期インターン';
    }

    default: {
      return '';
    }
  }
};

export const NotificationCard: React.FC<Props> = ({notification}) => {
  const {isPC} = useMediaQuery();

  return (
    <Link
      href={notification.detailURL}
      className="hover:text-link-1"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box
        className={`border border-solid bg-white border-white mb-16 py-8 px-16 flex pc:h-auto`}
      >
        <Box className="mt-4 text-center pc:min-w-[80px]">
          <Box className="w-[36px] pc:w-[58px] mx-auto">
            {getIcon(notification.eventType)}
          </Box>
          {isPC && (
            <div className="text-xs">{getTypeName(notification.eventType)}</div>
          )}
        </Box>
        <Box className="pl-12">
          <h2 className="text-md font-bold mb-2 pc:w-[508px]">
            {notification.title}
          </h2>
          {isPC && (
            <div className="text-base">
              {formatDateFromIsoDate(notification.createdAt)}
            </div>
          )}
          <p className="mt-2 text-sm pc:w-[508px]">
            <span className="line-clamp-1">{notification.body}</span>
          </p>
          {!isPC && (
            <div className="text-sm pt-16">
              {formatDateFromIsoDate(notification.createdAt)}
            </div>
          )}
        </Box>
      </Box>
    </Link>
  );
};
