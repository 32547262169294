import {Carousel} from '@mantine/carousel';
import {BasicButton} from '@packages/ui';
import {Loader, Image} from '@mantine/core';
import {useEffect, useState} from 'react';
import {
  useGetColumnCarouselList,
  useGetPostList,
} from '../../../../../core/repositories/columnRepository';
import {CommonLink} from '../../../../../shared/components/CommonLink';
import {useMediaQuery} from '../../../../../shared/services/useMediaQuery';
import {
  type ColumnCarousel,
  type PostListSchema,
} from '../../../../../core/entities/columns/schema';
import {formatDate} from '../../../../../shared/services/formatDate';
import {isNewPost} from '../../../../columns/usecases/isNewPost';
import {validateCarousel} from '../../../usecase/validateCarousel';
import {getMailAuthLinkWithParamsHooks} from '../../../../../shared/services/getMailAuthLinkWithParamsHooks';

const SPECIAL_POSTS = [
  {
    title: '就活を知る',
    description:
      '視野を広げ行動を起こすきっかけとなるような情報をまとめました。',
    linkURL: '/special/column_1',
    thumbnailURL: '/next-image/columns/thumbnail/special_column_1.jpg',
  },
  {
    title: '投資銀行研究',
    description:
      '投資銀行の特徴や企業ごとの違い、社員の特徴やキャリアなど、あらゆる角度から投資銀行に関する情報を集めました。',
    linkURL: '/special/column_6',
    thumbnailURL: '/next-image/columns/thumbnail/special_column_6.jpg',
  },
  {
    title: '総合商社研究',
    description: '就活生にトップクラスの人気を誇る商社を徹底研究。',
    linkURL: '/special/column_8',
    thumbnailURL: '/next-image/columns/thumbnail/special_column_8.jpg',
  },
  {
    title: 'コンサル研究',
    description:
      'コンサルティングを志望する方に向けた専門的な知識や業界事情、実際の業務内容などについてご紹介',
    linkURL: '/special/column_7',
    thumbnailURL: '/next-image/columns/thumbnail/special_column_7.jpg',
  },
];

export const NoLoggedInColumnList: React.FC = () => {
  const {isPC} = useMediaQuery();
  const [initialized, setInitialized] = useState(false);
  const [validatedCarousel, setValidatedCarousel] = useState<ColumnCarousel[]>(
    [],
  );

  const {data: carouselData, isLoading: isCarouselLoading} =
    useGetColumnCarouselList({});

  const {data: resentPostData, isLoading: isResentPostLoading} = useGetPostList(
    {queryParams: {limit: 6}},
  );

  useEffect(() => {
    const validateImages = async () => {
      if (!carouselData?.ok) return;

      const validatedImages = await validateCarousel(
        carouselData.value.carousel,
      );
      setValidatedCarousel(validatedImages);
      setInitialized(true);
    };

    void validateImages();
  }, [carouselData]);

  return (
    <div className="w-full pc:w-[1200px] bg-white pc:p-32 pc:mt-16 flex flex-col pc:flex-row justify-between items-stretch">
      <div className="w-full pc:w-[612px]">
        {isCarouselLoading && <LoadingComponent />}
        {carouselData?.ok &&
        !isCarouselLoading &&
        initialized &&
        validatedCarousel.length > 0 ? (
          <Carousel slideSize={'100%'} align={'start'} loop withIndicators>
            {validatedCarousel
              .filter((c) => c.imageURL)
              .map((carousel, index) => (
                <Carousel.Slide>
                  <CommonLink
                    key={`carousel-${index}`}
                    href={`archives/${carousel.id}`}
                    className="relative overflow-hidden flex"
                  >
                    <Image
                      src={carousel.imageURL ?? ''}
                      alt={`carousel-${index}`}
                      fit="cover"
                    />
                    <div className="absolute w-full bottom-0 bg-black bg-opacity-70 p-16">
                      <p className="text-md text-white font-bold text-wrap work-wrap line-clamp-2">
                        {carousel.title}
                      </p>
                    </div>
                  </CommonLink>
                </Carousel.Slide>
              ))}
          </Carousel>
        ) : null}
        {isPC && (
          <div className="hidden mt-16 pc:block">
            <p className="text-md font-bold leading text-black-1">特集</p>
            <div className="flex justify-between flex-wrap mt-[10px]">
              {SPECIAL_POSTS.map((post, index) => {
                return (
                  <CommonLink
                    key={`special-post-${index}`}
                    href={post.linkURL}
                    className="flex items-center w-[calc((100%-16px)/2)] h-[77px] hover:opacity-80 [&:nth-child(n+3)]:mt-16"
                  >
                    <div className="w-[77px] h-[77px] overflow-hidden">
                      <Image
                        src={post.thumbnailURL}
                        alt={post.title}
                        width={77}
                        height={77}
                        className="object-cover"
                      />
                    </div>
                    <div className="w-[calc(100%-77px)] pl-12">
                      <p className="text-link text-base leading">
                        {post.title}
                      </p>
                      <p className="text-black-1 text-xs leading mt-4 line-clamp-3">
                        {post.description}
                      </p>
                    </div>
                  </CommonLink>
                );
              })}
            </div>
            <RegisterNavigationButton />
          </div>
        )}
      </div>
      <div className="w-full pc:w-[504px] pc:h-[558px] flex flex-col justify-between">
        {resentPostData?.ok &&
        !isResentPostLoading &&
        resentPostData.value.posts.length > 0 ? (
          <div className="flex justify-between flex-wrap">
            {resentPostData.value.posts.map((post, index) =>
              isPC ? (
                <ResentPostComponentForPc post={post} index={index} />
              ) : (
                <RecentPostComponentForSp post={post} index={index} />
              ),
            )}
          </div>
        ) : (
          <LoadingComponent />
        )}
        <RegisterNavigationButton />
      </div>
    </div>
  );
};

const ResentPostComponentForPc: React.FC<{
  post: PostListSchema;
  index: number;
}> = ({post, index}) => {
  return (
    <CommonLink
      key={`resent-post-${index}`}
      href={`archives/${post.id}`}
      className="block relative [&:nth-child(n+3)]:mt-16 pc:hover:opacity-80"
    >
      <div className="w-[244px] h-[155px] overflow-hidden relative">
        <Image
          src={post.carouselThumbnailURL ?? ''}
          alt={post.title}
          width={244}
          height={155}
          className="object-cover"
        />
        {isNewPost({
          postDate: post.publishDateTime,
          currentDate: String(new Date()),
        }) && (
          <div className="absolute top-[5px] right-[-20px] bg-error py-[1px] px-[30px] origin-center rotate-[30deg]">
            <p className="text-white text-xs">NEW!!</p>
          </div>
        )}
      </div>
      <p className="bg-white opacity-80 text-black-1 text-sm p-4 absolute top-0 left-0">
        {post.tags[0]}
      </p>
      <div className="bg-black opacity-80 p-8 absolute bottom-0 left-0">
        <p className=" text-white text-base leading line-clamp-2 overflow-hidden">
          {post.title}
        </p>
      </div>
    </CommonLink>
  );
};

const RecentPostComponentForSp: React.FC<{
  post: PostListSchema;
  index: number;
}> = ({post, index}) => {
  return (
    <CommonLink
      key={`resent-post-${index}`}
      href={`archives/${post.id}`}
      className="w-full flex justify-between items-center p-12 border-b-[1px] border-line border-collapse [&:nth-last-child(1)]:border-b-0"
    >
      <div className="w-[calc(100%-85px)] h-[85px] pr-12 flex flex-col justify-between">
        <p className="text-black-1 text-base leading line-clamp-3 overflow-hidden">
          {post.title}
        </p>
        <p className="text-sm text-black-2 leading">
          {formatDate(post.publishDateTime)}
        </p>
      </div>
      <div className="w-[85px] h-[85px] overflow-hidden">
        <Image
          src={post.thumbnailURL ?? ''}
          alt={post.title}
          width={85}
          height={85}
          className="object-cover"
        />
      </div>
    </CommonLink>
  );
};

const RegisterNavigationButton: React.FC = () => {
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="mt-16 px-12 pc:px-0">
      <BasicButton
        label="無料会員登録してもっと見る"
        isPrimary={true}
        isLarge={true}
        onClick={() => {
          location.href = mailAuthLink;
        }}
        className="w-full"
      />
    </div>
  );
};

const LoadingComponent: React.FC = () => (
  <div className="flex justify-center py-24">
    <Loader color="gray" size="lg" />
  </div>
);
