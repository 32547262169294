import {
  COLUMN_RANKING,
  COLUMN_RECENTLY,
  COLUMN_SPONSORED,
  COLUMNS,
} from '../../../config/constants/routes';

type MainCategory = {
  path: string;
  label: string;
};

export const MAIN_CATEGORY_LIST: MainCategory[] = [
  {
    path: COLUMNS,
    label: 'カテゴリ',
  },
  {
    path: COLUMN_RANKING,
    label: 'ランキング',
  },
  {
    path: COLUMN_RECENTLY,
    label: '新着順',
  },
  {
    path: COLUMN_SPONSORED,
    label: '企業コラム',
  },
];

export const COLUMN_TYPE = ['special', 'nativeAd', 'companyPage'] as const;
export type ColumnType = (typeof COLUMN_TYPE)[number];

export const COLUMN_LIMITED_CONDITIONS = {
  LOGIN: '会員限定',
};

export const SPECIAL_POST_SLUGS = {
  COLUMN_1: 'column_1',
  COLUMN_2: 'column_2',
  COLUMN_3: 'column_3',
  COLUMN_4: 'column_4',
  COLUMN_5: 'column_5',
  COLUMN_6: 'column_6',
  COLUMN_7: 'column_7',
  COLUMN_8: 'column_8',
  COLUMN_9: 'column_9',
  COLUMN_10: 'column_10',
  COLUMN_11: 'column_11',
  INDEX: 'index',
  ENGINEER: 'engineer',
  OVERSEAS: 'overseas',
  QUIZ: 'quiz',
  CASE_INTERVIEW: 'caseinterview',
  SUMMER_INTERN: 'summer_intern',
  WINTER_INTERN: 'winter_intern',
  ENGINEER_INTERN: 'engineer_intern',
};

export const COLUMN_ARTICLE_TAG_REGEX = /\[article_set id="(\d+)"]/g;
export const COLUMN_ARTICLE_TAG_EMPTY_LI =
  /(<li class="text-base leading pl-20 w-1\/2 flex"><\/li>|<li><\/li>)/g;

export const COLUMN_COMPANY_STUDY_TAG_REGEX = /\[company_study id="(\d+)"]/g;
export const COLUMN_COMPANY_STUDY_TAG_EMPTY_LI =
  /(<li class="cs text-base leading pl-20"><\/li>|<li><\/li>)/g;
export const PARSE_COMPANY_STUDY_TAG_DATA_TYPE_SELECTION_REPORT =
  'selectionReport';
export const PARSE_COMPANY_STUDY_TAG_DATA_TYPE_ENTRY_SHEET = 'entrySheet';
