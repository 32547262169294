import {Loader} from '@mantine/core';
import {BasicButton} from '@packages/ui';
import {MdInsertDriveFile} from 'react-icons/md';
import Image from 'next/image';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {REPORTS} from '../../../../config/constants/routes';
import {useGetIndustryCategoryMaster} from '../../../../core/repositories/companyRepository';
import {getMailAuthLinkWithParamsHooks} from '../../../../shared/services/getMailAuthLinkWithParamsHooks';

export const NoLoggedInReportIndustryList: React.FC = () => {
  const {data, isLoading} = useGetIndustryCategoryMaster({queryParams: {}});
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="flex flex-col justify-between bg-white p-32 w-[580px] h-[398px]">
      <div>
        <div className="flex justify-start items-center">
          <div className="w-[24px]">
            <MdInsertDriveFile size={24} className="mx-auto" />
          </div>
          <h2 className="ml-12 text-lg font-bold text-black">ES・体験記</h2>
          <p className="ml-12 text-accent-1 text-sm border-[1px] border-accent-1 border-collapse p-4">
            会員限定
          </p>
        </div>
        <p className="text-base text-black-1 leading mt-12">
          先輩たちの本選考・インターンレポートやエントリーシートが読めます。
        </p>
      </div>
      <div>
        {data?.ok && !isLoading ? (
          <div className="flex flex-wrap">
            {data.value.industryCategories.map((category, index) => (
              <div
                key={`report-category-${index}`}
                className="w-1/2 h-[30px] mb-[10px] flex justify-start items-center"
              >
                <Image
                  src={`/next-image/top/icons/industry_${category.id}.png`}
                  alt={category.name}
                  width={24}
                  height={24}
                  className="object-contain"
                />
                <CommonLink
                  href={`${REPORTS}?category=${category.id}`}
                  className="w-full text-base text-link leading pr-8 hover:opacity-80 ml-12"
                >
                  {category.name}
                </CommonLink>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[200px] flex justify-center items-center">
            <Loader color="gray" size="lg" />
          </div>
        )}
      </div>
      <BasicButton
        isPrimary={true}
        isLarge={true}
        label="無料会員登録してもっと見る"
        onClick={() => {
          location.href = mailAuthLink;
        }}
        className="w-full"
      />
    </div>
  );
};
