import {Loader} from '@mantine/core';
import {BasicButton} from '@packages/ui';
import {MdForum} from 'react-icons/md';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {formatDate} from '../../../../shared/services/formatDate';
import {useGetCommunityTopicList} from '../../../../core/repositories/communityRepository';
import {getMailAuthLinkWithParamsHooks} from '../../../../shared/services/getMailAuthLinkWithParamsHooks';

export const NoLoggedInCommunityList: React.FC = () => {
  const {data, isLoading} = useGetCommunityTopicList({limit: 5});
  const mailAuthLink = getMailAuthLinkWithParamsHooks({});

  return (
    <div className="flex flex-col justify-between bg-white p-32 w-[580px] h-[398px]">
      <div>
        <div className="flex justify-start items-center">
          <div className="w-[24px]">
            <MdForum size={24} className="mx-auto" />
          </div>
          <h2 className="ml-12 text-lg font-bold text-black">コミュニティ</h2>
          <p className="ml-12 text-accent-1 text-sm border-[1px] border-accent-1 border-collapse p-4">
            会員限定
          </p>
        </div>
        <p className="text-base text-black-1 leading mt-12">
          同級生の就活生や先輩学生と情報交換・相談ができます。
        </p>
      </div>
      <div>
        {data?.ok && !isLoading ? (
          data.value.topics.map((topic, index) => (
            <div
              key={`community-${index}`}
              className="h-40 flex border-b-[1px] border-line boeder-collaspe"
            >
              <CommonLink
                href={`/questions/${topic.id}`}
                className="w-[calc(100%-85px)] flex justify-start items-center overflow-hidden hover:opacity-80"
              >
                <p className="text-base text-link leading line-clamp-1 pr-8">
                  {topic.title}
                </p>
              </CommonLink>
              <div className="w-[90px] flex justify-center items-center">
                <p className="text-sm text-black-1 text-center">
                  {formatDate(topic.lastPostedAt)}更新
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="h-[200px] flex justify-center items-center">
            <Loader color="gray" size="lg" />
          </div>
        )}
      </div>
      <BasicButton
        isPrimary={true}
        isLarge={true}
        label="無料会員登録してもっと見る"
        onClick={() => {
          location.href = mailAuthLink;
        }}
        className="w-full"
      />
    </div>
  );
};
